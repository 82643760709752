import axios from "axios";

class API {
    getChat = (id) => {
        return axios.get('https://api.ziegel.games/api/v1/chat-reports/' + id).then(res => {
            return res.data;
        })
    }
}

export default new API();