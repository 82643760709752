import {useEffect, useState} from "react";
import API from "./api/API";
import {Alert, Divider, Grid, Snackbar} from "@mui/material";
import moment from "moment";

const ChatArea = (props) => {
    const id = props.id;
    const [messages, setMessages] = useState([]);
    const queryParams = new URLSearchParams(window.location.search);
    const highlighted = queryParams.get('highlighted');
    const [error, setError] = useState(false);

    useEffect(() => {
        API.getChat(id).then(res => {
            setMessages(JSON.parse(res.json));
        }).catch(e => {
            setError(true);
        });
    }, []);

    if (error)
        return <Snackbar
            open={true}
            severity={'error'}
        >
            <Alert severity="error" sx={{ width: '100%' }}>
                {"Chat Report mit ID '" + id + "' konnte nicht gefunden werden"}
            </Alert>
        </Snackbar>

    return <Grid container direction='row' spacing={1} style={{padding: 10}}>
        {messages && messages.map(m => {
            return <Grid key={m.timestamp + m.sender} item xs={12} style={{color: highlighted && highlighted === m.sender ? 'orange' : ''}}>
                [{moment.unix(m.timestamp).format('DD.MM.YYYY HH:MM:ss')}]&nbsp;
                {m.sender}:
                &nbsp;
                {m.message}<br/>
                <Divider/>
            </Grid>
        })}
    </Grid>
}

export default ChatArea;