import './App.css';
import {Button, Grid, TextField} from "@mui/material";
import {useState} from "react";
import ChatArea from "./ChatArea";

function App() {
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    const [chatId, setChatId] = useState(undefined);
    return (
        <div className="App">
            {id ? <ChatArea id={id}/> :
                <header className="App-header">
                    <div>
                        <img
                            src={'https://cdn.tebex.io/store/875619/templates/87176/assets/049153a2da195f91480feb5f411515156d1c0ca0.png'}
                            className="App-logo" alt="logo"/>
                        <p>Bitte gib eine Chat-Report ID an</p>
                        <Grid container direction='row' spacing={3}>
                            <Grid item xs={10}>
                                <TextField fullWidth id="outlined-basic" size="normal" label="Chat-Report ID"
                                           variant="outlined"
                                           color='error' focused style={{color: '#fff'}}
                                           onChange={(e) => setChatId(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Button variant="contained" size='large' color='error' disabled={!chatId}
                                        onClick={() => {
                                            window.location.search += '?id=' + chatId;
                                        }}>
                                    Absenden
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </header>}
        </div>
    );
}

export default App;
